import $ from 'jquery'

class PageMask {
    constructor() {
        this.window = $(window)
        this.body = $('body')
        this.pageMaskEl = $('.c-page-mask')

        this.hidePageMask()

        this.window.on('beforeunload', this.handlePageExit.bind(this))

        // Handle Safari-specific cases
        this.window.bind('pageshow', this.handlePageShow)
        this.window.bind('pagehide', this.handlePageExit.bind(this))
    }

    hidePageMask() {
        this.body.addClass('page-mask-has-shown')
        setTimeout(() => {
            this.body.addClass('page-mask-has-shown--alt')
        }, 2500);
    }

    handlePageExit() {
        this.body.addClass('page-is-changing')
        this.body.removeClass('page-mask-has-shown')
    }

    handlePageShow(event) {
        if (event.originalEvent.persisted) {
            window.location.reload()
        }
    }
}

export default PageMask