import Rellax from "rellax";

class RellaxImages {
    constructor() {

      // template 10 offset images
      $(".no-touchevents .c-image--rellax").each(function (i) {
        $(this).attr("data-rellax-speed", i + 1 * 2);
        $(this).data("rellax-speed", i + 1 * 2);
      });
      var rellax = new Rellax(".no-touchevents .c-image--rellax");


      // template 8 offset block
      var rellax = new Rellax(".no-touchevents .c-media--offset-stack .c-media__image:first-of-type",
        {
          speed: 4,
          center: true,
        }
      );

    }
}

export default RellaxImages;