import $ from 'jquery'
import ScrollOut from 'scroll-out'
import { CountUp } from 'countup.js'

class NumericDefinitionList {
    constructor() {
        ScrollOut({
            targets: '.c-numeric-definition-list',

            onShown: el => {
                let numericValueEl = $(el).find('.js-numeric-definition-list-value')

                if (!numericValueEl.hasClass('has-animated')) {
                    numericValueEl.each(function() {
                        let thisEl = $(this)
                        let target = parseInt(thisEl.html())
                        let counter = new CountUp(this, target)

                        setTimeout(() => {
                            counter.start()
                            thisEl.addClass('has-animated')
                        }, 500)
                    })
                }
            }
        })
    }
}

export default NumericDefinitionList