import $ from 'jquery'

class ImageHoverCircle {
    constructor() {
        $('.c-cta--card .c-cta__buttons').each(function () {
            let svgEl = $('.js-item-hover').html()
            $(this).append(svgEl)

            $(this).on('mouseenter', function () {
                $(this).find('.js-item-hover--circle').addClass('js-hover-start')
            })

            $(this).on('mouseleave', function () {
                $(this).find('.js-item-hover--circle').removeClass('js-hover-start')
            })
        })
    }
}

export default ImageHoverCircle