export default class Navigation {
    constructor() {
        $.classes = {
            active: 'is-active',
            navActive: 'navigation--is-active',
            subActive: 'sub-menu-is-active',
            menuItem: '.menu__item'
        }

        $.body = $('body')
        $.navicon = $('.c-navicon')
        $.offcanvasMenu = $('.c-offcanvas__menu')
        $.offcanvasNav = $.offcanvasMenu.find('> .c-offcanvas__nav')
        $.subMenusContainer = $.offcanvasNav.find('> .sub-menus-wrapper')
        $.subMenuBackButton = $.offcanvasMenu.find('> .menu__back-button')
        $.menu = $.offcanvasNav.find('> .menu-wrapper > .menu')
        $.menuItems = $.menu.find($.classes.menuItem)
        $.menuItemsAnchors = $.menuItems.find('> a')
        $.currentMenuItem = $.menuItems.filter(`${$.classes.menuItem}--current`)
        $.ancestorMenuItem = $.menuItems.filter(`${$.classes.menuItem}--ancestor`)
        $.parentOfSubMenuItem = $.menuItems.filter(`${$.classes.menuItem}--has-children`)
        $.subMenuTriggers = $.parentOfSubMenuItem.find('> a')
        $.subMenus = $.parentOfSubMenuItem.find('> .sub-menu')

        $.offcanvasImages = $.offcanvasMenu.find(".c-offcanvas__background-image");
        $.defaultOffcanvasImage = $.offcanvasImages.filter(".c-offcanvas__background-image--default");

        // used to control the display of sub-menus on initial load for inner pages
        $.offcanvasMenu.initialSubMenuClosed = true;

        // used to scroll the container back to the top - aligned with CSS animations
        $.scrollContainer = (() => {
            setTimeout(() => {
                $.offcanvasNav[0].scrollTop = 0
            }, 200)
        })

        this.bindEventListeners();
        this.bindAndMoveSubmenus();
        this.setActiveSubMenus();
    }

    bindEventListeners() {
        $.navicon.on("click", (e) => {
            $.body.trigger('revealOffcanvas')

            if ($.body.hasClass($.classes.navActive)) {
                this.resetMenu(true)
            }
        })

        $.subMenuTriggers.on('click', (event) => {
            event.preventDefault()

            let target = $(event.target),
                targetParentId = target.parent().attr('id'),
                targetSubMenu = target.attr('data-target');

            this.revealSubMenu(targetSubMenu, targetParentId)
        })

        $.subMenuBackButton.on('click', () => {
            if(!$.offcanvasMenu.initialSubMenuClosed) {
                $.offcanvasMenu.initialSubMenuClosed = true;
            }

            this.resetMenu()
        })
    }

    bindAndMoveSubmenus() {
        $.subMenus.each((key, element) => {
            let anchor = $(element).siblings('a'),
                submenu = $(element)

            anchor.attr('data-target', `sub-menu-${key + 1}`)
            submenu.attr('data-target', `sub-menu-${key + 1}`)

            submenu.appendTo($.subMenusContainer)
        })
    }

    // set active sub menu
    setActiveSubMenus() {
        if (!$.ancestorMenuItem.length > 0) return

        $.offcanvasMenu.initialSubMenuClosed = false;

        let targetImageId = $.ancestorMenuItem.attr('id'),
            targetSubMenu = $.ancestorMenuItem.find('a').attr('data-target')

        this.revealSubMenu(targetSubMenu, targetImageId)
    }

    revealSubMenu(targetSubMenu, ImageId) {
        $(`[data-target="${targetSubMenu}"]`).addClass($.classes.active)
        $.body.addClass($.classes.subActive)
        $.offcanvasMenu.addClass($.classes.subActive)

        this.showRelatedSidebarImage(ImageId);

        if (!$.body.hasClass($.classes.navActive)) return

        $.scrollContainer()

        setTimeout(() => {
            $.menu[0].style.display = 'none';
        }, 300);
    }

    showRelatedSidebarImage(targetImageId) {
        let relatedSidebarImageEl = $.offcanvasImages.filter(`.c-offcanvas__background-image.${targetImageId}`)

        // initally removes sidebar image active classes
        $.offcanvasImages.removeClass($.classes.active)

        // sets default image to active if no related images are found, otherwise make proceed to make active
        if (!relatedSidebarImageEl.length > 0) {
            return $.defaultOffcanvasImage.addClass($.classes.active)
        }

        relatedSidebarImageEl.addClass($.classes.active)

        // retrigger offcanvas for any now loaded images when the nav is open
        if (!$.body.hasClass($.classes.navActive)) return
        $.body.trigger('revealOffcanvas')
    }

    resetMenu(closeMenu = false) {
        if (!$.offcanvasMenu.initialSubMenuClosed) return

        $.menu.removeAttr('style')
        $.body.removeClass($.classes.subActive)
        $.subMenus.removeClass($.classes.active)
        $.offcanvasMenu.removeClass($.classes.subActive)

        $.scrollContainer()

        if(!closeMenu) return

        setTimeout(() => {
            $.body.removeClass($.classes.navActive)
        }, 100);
    }
}
