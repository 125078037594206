import $ from 'jquery'

class CTAGrid {
    constructor() {
        $('.c-grid-filter__headings, .c-grid-filter__filter, .c-grid-filter__content').wrapAll('<div class="c-grid-filter__control"></div>');

        $('.c-grid-filter__radio-button').on('click', function () {
            $(window).trigger('resize');
            $('html, body').animate({ scrollTop: $('.c-grid-filter--cta').position().top }, 'slow');
        });
    }
}

export default CTAGrid