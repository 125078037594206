import Navigation from './modules/Navigation'
import CTAGrid from './modules/CTAGrid'
import ImageHoverCircle from './modules/ImageHoverCircle'
import SVGMask from './modules/SVGMask'
import PageMask from './modules/PageMask'
import RellaxImages from "./modules/RellaxImages";
import NumericDefinitionList from "../../widgets/numeric-definition-list/js/NumericDefinitionList";

new SVGMask()
new RellaxImages()
new ImageHoverCircle()
new CTAGrid()
new Navigation()
new PageMask()
new NumericDefinitionList();
